let slideshow_to = null,
  images = null,
  slideshow_paused = false
  video_paused = false,
  displaygrid = false,
  currentVideo = null,
  basesize = 2
  home = document.querySelector('#home'),
  button = document.querySelector('#button');

let idx = 0;

const slides = document.querySelectorAll(".slide");

let images_idx = document.createElement("div");
images_idx.className = "idx";

// navigation au clavier (flÃ¨ches directionelles)
document.body.onkeydown = function (e) {

  if (e.key == "ArrowLeft" || e.key == "ArrowRight" || e.key == " " || e.key == "Escape" || e.key == "+" || e.key == "-") {
    e.stopImmediatePropagation();
    e.preventDefault();
    if (e.key == "ArrowLeft") {
      console.log("ArrowLeft");
      changeSlide("left");
    }
    if (e.key == "ArrowRight") {
      console.log("ArrowRight");
      changeSlide("right");
    }
    if (e.key == " ") {
      if(slideshow_to){
        slideshow_paused = !slideshow_paused;
        if(slideshow_paused){
          document.body.classList.add('paused');
        } else {
          document.body.classList.remove( "paused");
        }
        
      } else if(currentVideo){
        if(currentVideo.paused){
          currentVideo.play()
        } else {
          currentVideo.pause();
        }
      }
      
    }
    if (e.key == "Escape") {
      console.log("escape");
      toggleGrid();      
    }
    if (e.key == "+") {
      console.log("+");
      basesize += .1;
      document.body.style.setProperty('--basesize', basesize + "vw")
    }
    if (e.key == "-") {
      console.log("-");
      basesize -= .1;
      document.body.style.setProperty('--basesize', basesize + "vw")
    }
  }
};

// toggle grid
function toggleGrid() {
  let body = document.body;
  if (!displaygrid) {
    body.classList.add('grid')
    displaygrid = true;
  } else {
    body.classList.remove('grid')
    displaygrid = false;
  }
}

// grid actions
function initGridActions(article){
  article.addEventListener('click', () => {
    if(displaygrid == true) {
      document.querySelector('.visible').classList.remove("visible");
      article.classList.add('visible');
      idx = parseInt(article.dataset.id) - 1;
      changeSlide("right");
      toggleGrid();
    }
  })
}


// home
button.onclick = () => {
  changeSlide("right");
}



// affichage du premier article
slides[0].classList.add("visible");

// attribution d’un id Ã  chaque article
slides.forEach(function (el, index, array) {
    el.dataset.id = index;
		initGridActions(el);
});

if (window.location.hash) {
  var myidx = window.location.hash.replace("#article-", "");
  var el = document.querySelector(window.location.hash);
  idx = Array.prototype.indexOf.call(slides, el) - 1;
  changeSlide("right");
}

document.body.ondblclick = function (e) {
  toggleFullScreen();
};

function toggleFullScreen() {
  if (!document.fullscreenElement) {
    document.documentElement.requestFullscreen();
    isfullscreen = true;
    document.body.classList.add("fs");
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
      isfullscreen = false;
      document.body.classList.remove("fs");
    }
  }
}

// changement de slide
function changeSlide(direction) {

  // quelle direction ?
  if (direction == "right") {
    idx = idx == slides.length - 1 ? 0 : idx + 1;
  } else {
    idx = idx == 0 ? slides.length - 1 : idx - 1;
  }

  // clear slideshow timeout
  if (slideshow_to) {
    clearTimeout(slideshow_to);
  }

  let ivisible = document.querySelector(".ivisible");
  if (ivisible) {
    ivisible.classList.remove("ivisible");
  }
  images = null;

  slides.forEach(function (el, index, array) {
    if (index == idx) {
      el.classList.add("visible");
      history.pushState(null, el.id, "#" + el.id);
      images = el.querySelectorAll("figure");

      // do slideshow
      if (images.length > 1) {
        doSlideshow(el, images);
      }

      // auto build iframes
      let embed = el.matches('.embed');
      if (embed) {
        let iframe = document.createElement('iframe');
        iframe.src = el.getAttribute('rel');
        iframe.setAttribute('width', 854);
        iframe.setAttribute('autoplay', 'true');
        iframe.setAttribute('height', 480);
        iframe.setAttribute('frameborder', 0);
        el.querySelector('.slide-content').appendChild(iframe);
        el.classList.add('embedded');
        iframe.contentWindow.addEventListener('keydown', function(e){ 
          bindKeys(e)
        }, true);
      }

      // autoplay videos
      let video = el.querySelector('video');
      if (video) {   
        currentVideo = video;
        if(video.dataset.start){
          video.currentTime = parseInt(video.dataset.start);
          setTimeout(() => {
            video.play();
          }, 100);
        } else {
          video.play();
        }
      } else {
        currentVideo = null ;
      }

    }
    // Sinon
    else {
      el.classList.remove("visible");
      let iframe = el.querySelector('iframe');
      if (iframe) {
        iframe.parentElement.removeChild(iframe);
      }
      let video = el.querySelector('video');
      if (video) {   
        video.pause()
      }
    }
  });
}

function doSlideshow(el, images) {
  el.appendChild(images_idx);
  setId(1, images.length);
  const firstimage = images[0];
  firstimage.classList = "ivisible";
  let has_time = firstimage.dataset.time;
  let isgif = firstimage.querySelector('img').src.match(/\.(gif)$/i);
  if (isgif) has_time = 10000;
  if (has_time) {
    time = parseInt(has_time);
  } else {
    time = 2500;
  }
  setTimeout(nextImage, time);
}

function setId(id, length) {
  images_idx.innerHTML = `<span>${id}</span><br>/<br>${length}`;
}

function nextImage() {
  let time = 2500;
  if (slideshow_paused) {
  } else {
    let ivisible = document.querySelector(".ivisible");
    let next = ivisible.nextElementSibling;
    if (next === null) {
      next = ivisible.parentElement.firstElementChild;
    }
    let isgif = next.querySelector('img').src.match(/\.(gif)$/i);
    if (isgif) time = 10000;

    let has_time = next.dataset.time;
    if (has_time) {
      time = parseInt(has_time);
    }

    ivisible.classList.remove("ivisible");
    next.classList.add("ivisible");

    if (next.tagName == "VIDEO") next.play();
    setId(getNodeindex(next) + 1, images.length);
  }
  slideshow_to = setTimeout(nextImage, time);
  console.log(time);
}

const getNodeindex = (elm) => [...elm.parentNode.children].indexOf(elm);
